.PaymentSummary {
  width: 40%;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PaymentSummary .payInfo {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
  background-color: #B68A35;
  border-radius: 4px;
  height: 86px;
  padding: 14px;
}

.PaymentSummary .payInfo .payInfoLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PaymentSummary .payInfo .payInfoLabel p{
color: #FFFFFF;
font-size: 12px;
}

.PaymentSummary .payInfo .payInfoAmount {
  background-image: url('../../../../public/assets/img/payment/payMask.svg');
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.PaymentSummary .payInfo .payInfoAmount p{
color: #FFFFFF;
font-size: 28px;
font-weight: bold;
}

.PaymentSummary .payInfo .payInfoAmount span{
color: #FFFFFF;
font-size: 12px;
}

.PaymentSummary .PaymentSummaryContainer {
  background-color: #fff;
  padding: 16px;
  max-height: 283px;
  border-radius: 4px;
  border: 1px solid #EEE2D0;
}

.PaymentSummary .PaymentSummaryContainer .PaymentSummaryBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PaymentSummary .PaymentSummaryContainer .PaymentSummaryBody .PaymentSummaryRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PaymentSummary .PaymentSummaryContainer .PaymentSummaryBody .PaymentSummaryRow h2{
  color: #010202;
  font-size: 12px;
}

.PaymentSummary .PaymentSummaryContainer .PaymentSummaryBody .PaymentSummaryRow p{
  color: #010202;
  font-size: 14px;
  font-weight: bold;
  width: 60%;
}