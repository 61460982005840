.pagination {
  display: flex;
  /* border: 1px solid #eee2d0;
  background-color: #fff; */
  padding: 6px;
  /* max-width: 400px; */
  max-width: 480px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 12px; */
  border-radius: 8px;
}

.paginationPages {
  display: flex;
  padding: 0;
  border: 1px solid #b68a35;
  border-radius: 25px;
  margin: 0 8px;
}

.pageItem {
  border: 1px solid black;
  padding: 4px;
  /* margin-left: 4px; */
  display: flex;
  align-items: center;
  /* justify-self: center; */
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: black;
  cursor: pointer;
  border: transparent;
  border-radius: 20px;
}
.pageItem:hover {
  background-color: #b68a35;
  color: #fff;
}
.pageItem a {
  /* margin: auto; */
  /* width: 32px;
  height: 17px;
  margin-inline-start: 13px;
  margin-bottom: 7px; */
}

.active {
  background-color: #b68a35;
  color: #fff;
}

.disable {
  /* background-color: #dedede !important; */
  cursor: not-allowed !important;
}

.pageItemNext {
  border: 1px solid black;
  padding: 4px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 40px;
  height: 40px;
  background-color: #dedede;
  color: #fff;
  cursor: pointer;
  border: transparent;
  border-radius: 20px;
}

.pageItemNextMobile {
  border: 1px solid black;
  padding: 4px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 40px;
  height: 40px;

  color: #fff;
  cursor: pointer;
  border: transparent;
  border-radius: 20px;
}

.pageItemNextMobile img {
  width: 24px;
  height: 24px;
}

.pageItemNext img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.pageItemPrev {
  border: 1px solid black;
  padding: 4px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 40px;
  height: 40px;
  background-color: #eee2d0;
  color: #fff;
  cursor: pointer;
  border: transparent;
  border-radius: 20px;
}
.pageItemPrev img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.pageItemPrevMobile{
  border: 1px solid black;
  padding: 4px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 40px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  border: transparent;
  border-radius: 20px;
}

.pageItemPrevMobile img{
  width: 24px;
  height: 24px;
}
