.PaymentMethodContainer{
width: 60%;
display:  flex;
flex-direction: column;
gap: 11px;
}

.PaymentMethodContainer .payAccordion {
  display: flex;
  flex-direction: row;
}

.PaymentMethodContainer .payAccordion .payAction {
  border-radius: 4px 0px 0px 4px;
background-color: #B68A35;
display: flex;
width: 80px;
align-items: center;
justify-content: center;
gap: 4px;
cursor: pointer;
border-right: none;
}

.PaymentMethodContainer .payAccordion .payAction p{
  color: #FFFFFF;
  font-size: 12px;
}

.PaymentMethodContainer .payAccordion .payAction img{
  width: 16px;
height: 16px;
}

.PaymentMethodContainer .payAccordion .summaryAccordion {
  display: flex;
  background-color: #fff;
  padding: 17px 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #EEE2D0;
  border-left: none;
}

.PaymentMethodContainer .payAccordion .summaryAccordion .payHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.PaymentMethodContainer .payAccordion .summaryAccordion .payHead .radioLabelWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.PaymentMethodContainer .payAccordion .summaryAccordion .payHead .radioLabelWrapper .accordionLabel {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: bold;
  color: #404040;
}

.PaymentMethodContainer .payAccordion .summaryAccordion .payHead .radioLabelWrapper .accordionLabel:hover {
  cursor: pointer;
}


.PaymentMethodContainer .payAccordion .summaryAccordion .payHead .logosWrapper {
  display: flex;
  gap: 16px;
}