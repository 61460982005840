.modalBg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 1000;
}

.modalBg .modalContainer {
  min-width: 528px;
  min-height: 344px;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  margin: 0 24px;
}

.modalBg .modalContainerContact {
  min-width: 528px;
  width: 712px !important;
  min-height: 344px;
  /* max-height: 600px; */
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  /* update */
  /* overflow: scroll;
  overflow-x: hidden; */
}

@media screen and (max-width: 768px) {
  .modalBg .modalContainerContact {
    min-width: 528px;
    width: 712px !important;
    /* min-height: 344px; */
    max-height: 600px;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 16px;
    /* update */
    overflow: scroll;
    overflow-x: hidden;
    margin: 0 24px;
  }
}

.modalBg .modalRequestSuccessMessage {
  min-width: 360px !important;
  width: 360px !important;
  min-height: 253px;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 24px;
}

.modalBg .modalShowImage {
  min-width: 528px;
  min-height: 344px;
  max-height: 600px;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 24px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

.modalBg .modalHappinessContainer {
  min-width: 528px;
  /* width: 360px !important; */
  min-height: 320px;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
}

.modalBg .modalPhisical {
  width: 432px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
}

.modalBg .modalReceiptForm {
  width: 432px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
}

.modalBg .modalNeedMoreData {
  width: 432px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
}

.modalBg .modalSuccessUpload {
  width: 432px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
}

.modalBg .modalAdmission {
  width: 714px !important;
  height: 413.2px;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBg .modalFailedPayment {
  width: 360px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBg .modalSuccessPayment {
  width: 360px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalBg .successMobileSurvey {
  width: 360px !important;
  background-color: #ffffff;
  box-shadow: 0px 25px 50px #361e1226;
  border: 1px solid #eee2d0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .modalBg .modalAdmission {
    width: 714px !important;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px;
  }
}

@media screen and (max-width: 640px) {
  .modalBg .modalPhisical {
    width: 327px !important;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px !important;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .modalBg .modalHappinessContainer {
    min-width: 528px;
    /* min-height: 344px; */
    height: auto;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 16px;
    margin: 0 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .modalBg .modalShowImage {
    min-width: 528px;
    /* min-height: 344px; */
    max-height: 600px;
    max-height: 150px;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 24px;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 640px) {
  .modalBg .modalFailedPayment {
    width: 327px !important;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px !important;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  .modalBg .modalSuccessPayment {
    width: 327px !important;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px !important;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  .modalBg .successMobileSurvey {
    width: 327px !important;
    background-color: #ffffff;
    box-shadow: 0px 25px 50px #361e1226;
    border: 1px solid #eee2d0;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px !important;
    height: auto;
  }
}
