@media screen and (min-width: 400px) {
  .table {
    overflow-x: auto !important;
  }
}

@media screen and (max-width: 1024px) {
  table {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100% !important;
  }
}

tbody {
  font-size: 13px;
  font-weight: 500;
}

.HeadTable {
  background: #ffffff;
  box-shadow: 0px 4px 16px #361e1214;
  border: 1px solid #e6d7a2;
  border-radius: 20px !important;
}
.Mainth {
  display: flex;
  /* margin: auto; */
  width: fit-content;
  margin-bottom: 8px;
  align-items: center;
  justify-content: flex-start;
}
.Mainth img {
  cursor: pointer;
  margin-right: 1px;
}

.Mainth p {
  width: 90px;
}

.typeRequest {
  margin-bottom: 8px;
}
.numberRequestTh {
  width: 85px;
  padding: 12px 16px 12px 8px;
}
.numberRequestThDestalis {
  padding: 12px 16px 12px 64px;
  width: 165.5px;
}

.numberRequestThDestalisEn {
  padding: 12px 64px 12px 16px;
  width: 165.5px;
}
.numberRequest {
  width: 85px;
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}
.typeRequestTh {
  width: 148px;
  padding: 12px 8px 12px 8px;
}
.typeRequestThDetails {
  padding: 12px 8px 12px 64px;
  width: 220px;
}

.typeRequestThDetailsEn {
  padding: 12px 64px 12px 8px;
  width: 220px;
}

.numberCaseTh {
  width: 95px;
  /* width: 198px; */
  padding: 12px 8px 12px 8px;
}

.numberCase {
  /* width: 88px; */
  width: 95px;
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}

.typeCourtTh {
  width: 114px;
  padding: 12px 8px 12px 8px;
}

.typeCourt {
  width: 88px;
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}

.courtTh {
  width: 97px;
  padding: 12px 8px 12px 8px;
}
.court {
  width: 50px;
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}

.requestStatusTh {
  /* width: 111px; */
  width: 145px;
  padding: 12px 8px 12px 8px;
}

.requestStatusThDetails {
  padding: 12px 8px 12px 64px;
  width: 111px;
}

.requestStatusThDetailsEn {
  padding: 12px 64px 12px 8px;
  width: 111px;
}

.requestStatus {
  /* width: 67px; */
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}

.courtStatusTh {
  width: 113px;
  padding: 12px 8px 12px 8px;
}
.courtStatus {
  /* width: 70px; */
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}

.dateRequestTh {
  width: 109px;
  padding: 12px 8px 12px 8px;
}
.dateRequest {
  width: 116px;
  text-align: right;
  margin: 0;
  margin-bottom: 8px;
}

.sectionInput {
  display: flex;
  position: relative;
}

.sectionInput img {
  position: absolute;
  left: 0;
  top: 5px;
  cursor: pointer;
}

.sectionInputImgAr {
  right: 0 !important;
  left: auto !important;
}

.MainInput {
  width: 95px;
  /* width: 198px; */
  height: 24px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 0.5px solid #c7c7c7;
  /* border-radius: 4px; */
  opacity: 1;
  padding: 10px;
  background-color: ghostwhite;
  /* margin-top: 8px; */
  font-size: 12px;
  font-weight: normal;
  /* NewDesign */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -0.5px 0px #909090;
  opacity: 1;
  border: 0;
  /* padding: 0 5px; */
  padding: 0 0px;
}

.MainInput:focus {
  /* border: transparent !important; */
  border: none !important;
  border: 0;
  outline: none !important;
}

.mainHead {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.mainHead button {
  margin-bottom: 16px;
}

/* .textHead {
  width: 50%;
} */

.texttd {
  text-align: right;
}

.numberRequestTd {
  width: 85px;
  padding: 12px 16px 12px 8px;
  text-align: right;
}

.numberRequestTdEn {
  text-align: left;
  padding: 12px 8px 12px 16px;
  width: 85px;
}

.typeRequestTd {
  width: 148px;
  padding: 12px 8px 12px 8px;
  text-align: right;
  color: #af8c45;
  text-decoration-line: underline;
}

.typeRequestTdEn {
  width: 148px;
  padding: 12px 8px 12px 8px;
  text-align: left;
  color: #af8c45;
  text-decoration-line: underline;
}

.numberCaseTd {
  width: 88px;
  padding: 12px 8px 12px 8px;
}

.numberCasetext {
  text-align: right;
  color: #af8c45;
  text-decoration-line: underline;
}

.numberCasetextPending {
  text-align: right;
}

.numberCaseTdEn {
  width: 88px;
  padding: 12px 8px 12px 8px;
  /* text-align: left;
  color: #af8c45;
  text-decoration-line: underline; */
}

.numberCaseTdEntext {
  text-align: left;
  color: #af8c45;
  text-decoration-line: underline;
}

.numberCaseTdEntextPending {
  text-align: left;
}

.typeCourtTd {
  width: 114px;
  padding: 12px 8px 12px 8px;
  text-align: right;
}

.typeCourtTdEn {
  width: 114px;
  padding: 12px 8px 12px 8px;
  text-align: left;
}
.courtTd {
  width: 97px;
  padding: 12px 8px 12px 8px;
  text-align: right;
}

.courtTdEn {
  width: 97px;
  padding: 12px 8px 12px 8px;
  text-align: left;
}

.requestStatusTd {
  /* width: 127px; */
  padding: 12px 8px 12px 8px;
  text-align: right;
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 72px;
}

.requestStatusTdEn {
  /* width: 170px; */
  padding: 12px 8px 12px 8px;
  text-align: left;
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 72px;
}

.courtStatusTd {
  width: 113px;
  padding: 12px 8px 12px 8px;
  text-align: right;
}

.courtStatusTdEn {
  width: 113px;
  padding: 12px 8px 12px 8px;
  text-align: left;
}

.dateRequestTd {
  width: 109px;
  padding: 12px 8px 12px 8px;
  text-align: right;
}

.dateRequestTdEn {
  width: 95px !important;
  padding: 12px 8px 12px 8px;
  text-align: left;
}

.actionTd {
  /* width: 105px;  */
  padding: 16px 8px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  height: 72px;
}

.actionTd p {
  cursor: pointer;
  color: #b68a35;
  font-weight: bold;
  font-size: 13px;
}

.actionTdEn {
  width: 107px;
  /* padding: 16px 16px 16px 46px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  cursor: pointer;
  height: 72px;
}

.actionTdEn p {
  font: normal normal normal 14px/40px Almarai Bold;
  letter-spacing: 0px;
  color: #b68a35;
  opacity: 1;
}

.actionSection {
  /* width: 105px; */
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.actionSection p {
  width: 104px;
  /* text-align: right; */
  font-weight: bold;
  font-size: 14px;
  font-family: "Almarai";
  letter-spacing: 0px;
  color: #b68a35;
  opacity: 1;
  /* text-overflow: ellipsis;
  text-wrap: balance; */
}

.actionSection img {
  width: 8px;
}

.actionSectionImgEn {
  rotate: 180deg;
}

.actionSectiontextEn {
  text-align: right !important;
  /* font-weight: 500 !important; */
}

.selectLine {
  border: 0;
  box-shadow: inset 0px -0.5px 0px #909090;
  background-position-x: 10px;
}

.selectLineAR {
  border: 0;
  box-shadow: inset 0px -0.5px 0px #909090;
  background-position-x: 10px;
  background-position: right;
  background-position-x: 90%;
}

.selectLineEn {
  border: 0;
  box-shadow: inset 0px -0.5px 0px #909090;
  background-position-x: 10px;
  background-position: right;
  background-position-x: 95%;
}

select:focus {
  border: none;
  outline: none !important;
}

.linkBtn {
  display: flex;
}

.Even {
  background-color: #fff1f0;
}

.odd {
  background-color: #fff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -1px 0px #ebebeb;
}

.statuesSquare {
  rotate: 30deg;
  color: #ffa500;
}

.statuesSquare1 {
  rotate: 30deg;
  color: #fb5733;
}

.statuesSquare2 {
  rotate: 30deg;
  color: #3498db;
}

.statuesSquare3 {
  rotate: 30deg;
  color: #b68a36;
}

.statuesSquare4 {
  rotate: 30deg;
  color: #27ae60;
}

.overview-Footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overview-FooterPages {
  display: flex;
  align-items: center;
}

.overview-FooterText {
  width: 146px;
  height: 17px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-almarai-bold);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
  text-align: center;
  font: normal normal normal 16px/17px Almarai Bold;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-weight: bold;
  font-size: 16px;
}

.overview-FooterDropDown {
  width: 77px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #eee2d0;
  border-radius: 8px;
  opacity: 1;
  background-position-x: 12px;
  padding: 0 12px;
}

.overview-FooterDropDownEn {
  width: 77px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #eee2d0;
  border-radius: 8px;
  opacity: 1;
  background-position-x: 40px;
  padding: 0 12px;
}

select:focus {
  background-color: ivory;
  border: transparent;
}

.overview-FooterTextBold {
  width: 126px;
  height: 17px;
  /* UI Properties */
  opacity: 1;
  /* margin-inline-start: auto; */
}

.overview-FooterTextBold p {
  font-weight: bold;
}

.overview-FooterTextBold p span {
  margin: 0 5px;
}

.overview-selectCourt {
  width: 84px;
  height: 24px;
  /* UI Properties */
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 0.5px solid #c7c7c7;
  border-radius: 4px;
  opacity: 1;
}
