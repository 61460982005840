/* width */
::-webkit-scrollbar {
    width: 5px;
    
  }

  ::-webkit-scrollbar-x {
    width: 5px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 16px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c5aa73;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b68a35;
  }