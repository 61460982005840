.noRequestImages {
  width: 170px;
  height: 170px;
  margin: auto;
  margin-bottom: 32px;
  
}

.noRequestImages img {
  width: 100%;
  height: 100%;
}

.btnAction {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.noRequesttext {
  font-size: 18px;
  font-family: "Almarai";
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: center;
  margin: auto;
}

.noRequestDesc {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.03px;
  color: #838383;
  text-align: center;
  margin-bottom: 15px;
  max-width: 364px;
  margin: 12px auto 24px auto;
  font-family: "Almarai";
}

/* @media screen and (max-width: 500px) {
  .btnAction {
    flex-direction: column;
    gap: 10px;
  }

  .linkAction {
    width: 100%;
  }

  .actions {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
} */

.mainSection {
  max-width: 1288px;
  height: 604px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px -1px 0px #ebebeb;
  border-radius: 12px 12px 0px 0px;
  opacity: 1;
  padding: 133px 0 120px 0;
  /* margin-top: -90px; */
}
