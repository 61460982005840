.center .slick-center .Slide {
  transform: scale(1.3);
  transition-duration: 1s;
  background-color: #fff !important;
  width: 163px !important;
  height: 150px !important;
  padding: 12px 24px !important;
  margin: 30px 20px !important;
  z-index: 10000;
  cursor: pointer;
  opacity: 0.95;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.85);
}

.center .Slide {
  opacity: 2;
  /* cursor: not-allowed; */
}

/* 

.center .Slide:hover {
  width: 163px;
  height: 150px;
  padding: 12px 24px !important;
  margin: 30px 20px !important;
  transform: scale(1.3);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  opacity: 1;
  color: #000;
  animation-duration: 300ms !important;
  transition: all 300ms linear !important;

} */

.center .Slide .par {
  width: 164px;
}

.center .slick-center .par {
  color: black !important;
  width: 164px !important;
  /* font-size: 18px !important; */
  height: 20px !important;
  /* font-family: "Almaria"; */
  font-weight: bold;
}

/* .slick-slide.slick-active.slick-center{
    width: 241px !important;
} */

.center .slick-center .btn {
  margin-top: 8px !important;
}

.center .slick-center .btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 135px !important; */
  width: 60px !important;
  font-size: 16px !important;
  color: #fff !important;
  height: 25px !important;
  font-family: "AlmaraiBold";
}

.center .btn a {
  width: 84px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .slick-track{
    width: 100% !important;
} */

/* .slick-list{
  padding: 0px 370px !important; 
} */

[dir="rtl"] .slick-slide {
  float: left !important;
}

[dir="rtl"] .slick-prev {
  display: none !important;
}

[dir="rtl"] .slick-next {
  display: none !important;
}

.slick-slide.slick-cloned {
  /* width: 152px !important;
  margin: 0 24px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-slide.slick-active.slick-center.slick-current {
  /* margin: 0 24px !important;
  width: 212px !important;
  width: 240px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-slide.slick-active.slick-center {
  /* margin: 0 24px !important;
  width: 212px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-slide.slick-active {
  /* width: 152px !important;
  margin: 0 24px !important;  */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-slide {
  /* width: 152px !important;
  margin: 0 24px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
