.surveyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.surveyHead {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.surveyHeadLogo img {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.surveyHeadTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.surveyHeadTitle h2 {
  color: #010202;
  font-size: 20px;
  font-weight: 600;
}

.surveyHeadTitle p {
  color: #807e7e;
  font-size: 14px;
}

.surveySubHead {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;
}

.surveySubHeadTitle h2 {
  color: #010202;
  font-size: 20px;
  font-weight: 600;
}

.surveySubHeadSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fcbc34;
  border-radius: 12px;
  width: 135px;
  height: 40px;
  padding: 0px 4px 0px 8px;
}

.surveyHappinessMeter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.surveyHappinessMeter img {
  width: 42px;
  height: 32px;
  object-fit: contain;
}

.surveyHappinessMeter p {
  color: #010202;
  font-size: 11px;
  font-weight: 600;
}

.surveyHappinessMeterBack {
  cursor: pointer;
}

.surveyHappinessMeterBack img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.surveyDetailsWrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.surveyDetails {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #361e1226;
  padding-bottom: 16px;
  gap: 18px;
}

.surveyDetails:last-of-type {
  border-bottom: unset;
  padding-bottom: unset;
}

.surveyDetailsHead h3 {
  color: #010202;
  font-size: 18px;
}

.questionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.questionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.questionTitle p {
  color: #010202;
  font-size: 14px;
  font-weight: 600;
}

.answersWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.answerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f7ed;
  border-radius: 12px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.answerWrapper img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.answerActiveWrappe {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b68a36;
  box-shadow: 0px 3px 6px #b68a3626;
  border-radius: 12px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.answerActiveWrappe img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.surveyMessageWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 28px;
}

.surveyMessageHead h2 {
  color: #010202;
  font-size: 12px;
  font-weight: 600;
}

.surveyMessage textarea {
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  width: 100%;
  height: 100px;
  -webkit-tap-highlight-color: transparent !important;
}

.surveyMessage textarea:hover,
.surveyMessage textarea:active,
.surveyMessage textarea:focus,
.surveyMessage textarea:focus-visible {
  border: 1px solid #cacaca !important;
}

.surveyMessage textarea::placeholder {
  color: #d9d9d9;
  font-size: 14px;
}

.surveyButtonWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.surveyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 46px;
  width: 100%;
  background: #b68a35;
  border-radius: 8px;
}

.surveyButton p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
