/* .phonee{
    padding: 0 20px;
} */

/* .phonee .form-control{
display: flex;
text-align: right !important;
width: 100% !important;
padding-right: 48px !important;

}

.phonee .form-control:focus{
    border: 1px solid rgb(185, 185, 185);
}



.phonee .arrow{
    right: 25px !important;
}

.phonee .flag{
   margin-right: 5px;
 
}

.phoneen .form-control:focus{
    border: 1px solid rgb(185, 185, 185);
} */

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.remove-arrow {
    -moz-appearance: textfield;
}

