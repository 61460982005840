.center .slick-center .Slide {
  transform: scale(1.3);
}

/* .center .slick-center .Slide p{
    width: 164px;
    font-size: 18px !important;
} */

/* .slick-slider{
    padding: 25px 0;
} */

.slick-initialized .slick-slide {
  display: block;
  /* width: 152px !important; */
}

/* .slick-dots{
 color: red;
} */

.slick-dots li button:before {
  color: #b68a35;
}
