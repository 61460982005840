.PaymentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 28px;
  margin-top: 30px;
}

.PaymentWrapper .PaymentHead {
  display: flex;
}

.PaymentWrapper .PaymentHead h2{
  font-size: 20px;
  font-weight: bold;
  color: #010202;
}

.PaymentWrapper .PaymentContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 16px;
}