@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/css";
@import "swiper/css/bundle";

body,
html {
  width: 100%;
}

*[dir="rtl"] {
  @apply font-Almarai;
}
.swiper-button-next {
  color: #fff;
}
.swiper-button-prev {
  color: #fff;
}
.swiper-pagination-bullet-active-main {
  background: #fff;
}

.terms h2 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 3rem;
  margin-top: 30px;
}
.terms span {
  color: rgba(34, 43, 69, 0.7);
  font-weight: 500;
}
.terms p,
.terms li {
  font-weight: 500;
  color: rgba(34, 43, 69, 0.7);
  margin-bottom: 3rem;
  line-height: 1.9;
}

.terms h4 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.PhoneInputInput {
  border-width: 0px;
  width: 100%;
  border-radius: 0.375rem;
  padding-top: 0.39rem;
  padding-bottom: 0.39rem;
}
[type="tel"]:focus {
  outline-offset: 0px;
  outline: 0;
  width: 100%;
  border: 0;
  border-color: #b68a36;
  --tw-ring-color: #b68a36;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-shadow: 0px;
}
.intl-tel-input .country-list .country {
  font-size: 14px;
}

