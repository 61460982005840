.rts___tabs {
  padding: 0 !important;
}
.rts___tab {
  margin: 0;
  position: relative;
  padding: 0 !important;
}
.rts___nav___btn svg {
  max-width: unset;
}

.rts___btn {
  border-radius: unset;
  border: none;
}

.rts___tab::after {
  content: "";
  margin: auto;
  height: 3px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  width: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.rts___tab___selected {
  color: #000;
  position: relative;
  width: 100%;
  background: transparent;
  box-shadow: none;
}
.rts___tab___selected::after {
  background: #b68a35;

  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
}
.rts___nav___btn:hover {
  background-color: unset;
}
.rts___nav___btn:hover > svg {
  stroke: rgba(0, 0, 0, 0.6);
}

@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px;
  }
}
