.half_circle {
   border-bottom-left-radius: 90px;
   border-top-left-radius: 90px;
   border-color: #e6d7a2 !important;
   border: 1px solid #e6d7a2;
   border-right: 0px !important;
 }

 .half_circleEN {
    border-bottom-right-radius: 90px;
    border-top-right-radius: 90px;
    border-color: #e6d7a2 !important;
    border: 1px solid #e6d7a2;
    border-left: 0px !important;
  }

  #inner{
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg); 
    background-color: #fff;
    width:130px;
    height:219px;
    top: 0px;
    left: -50px;
    position:relative;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

#innerEn{
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg); 
  background-color: #fff;
  width:130px;
  height:219px;
  top: -58px;
  right: -50px;
  position:relative;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#outer {
    position: absolute;
    width: 80px;
    height: 164px;    
    top:20px;
    left:-80px;
     overflow: hidden;    
    /* border: 1px solid red; */
    rotate: 180deg;
}

#outerEn {
  position: absolute;
  width: 80px;
  height: 164px;    
  top:20px;
  right:-80px;
   overflow: hidden;    
  /* border: 1px solid red; */
  rotate: 180deg;
}

#inners{
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg); 
  background-color: #fff;
  width:327px;
  height:327px;
  top: 0px;
  left: 0px;
  position:relative;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#outers {
  position: absolute;
  width: 100px;
  height: 327px;    
  top:46px;
  left:113px;
   overflow: hidden;    
  /* border: 1px solid red; */
  rotate: 90deg;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* width: 250px !important; */
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}


